@import "./src/assets/scss/main.scss";









































































































































.food-item {
    position: relative;
    background: #F5F5F5;
    border-radius: 12px;
    // margin-bottom: 37px;
    height: 100%;

    @include media-breakpoint-down(md) {
        margin-bottom: 10px;
    }
}

.food-item__img {
    max-height: 255px;
    overflow: hidden;
    border-radius: 12px 12px 0 0;

    img {
        width: 100%;
        height: auto;
    }
}

.food-item__noimg{
    padding-top: 82%;
    width: 100%;
        border-radius: 12px 12px 0 0;
      background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
      @include media-breakpoint-only(xs){
        padding-top: 100%; 
      }
    @include media-breakpoint-only(sm){
        padding-top: 86%; 
      }
      @include media-breakpoint-only(md){
        display: none;
      }
}

.food-item__meta {
    padding: 15px 20px;
position: relative;
//height: 200px;
height: auto;
    padding-bottom: 74px;
    @include media-breakpoint-down(md) {
        padding: 12px 10px;
        padding-bottom: 70px;
    }
}

.food-item__name {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 3px;

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 23px;
    }

    span {}
}

.food-item__kkal {
    font-family: Montserrat;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    color: #00BC13;
}

.food-item__weight {
    font-size: 14px;
    line-height: 17px;
    font-family: Montserrat;
    margin-bottom: 10px;

    align-items: center;

    color: #828282;
}

.food-item__desc {
    font-family: Montserrat;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 17px;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.food-item__price {
    color: $orange;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    span {
        display: block;
        font-size: 14px;
        line-height: 19px;
        font-weight: normal;
    }
}

.food-item__add-cart {}

.btn-orange-middle {
    @extend .btn;
    background: $orange;
    white-space: nowrap;
    border-radius: 12px;
    font-weight: 600;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    padding: 14px 22px;

    &:hover {
        background: none;
        border: 1px solid $orange;
        transition: all .2s ease;
        color: $orange;
    }
}

.yellow {
    color: #FF7728;
}

.red {
    color: red;
}
.food-item__bottom{
    position: absolute;
    bottom: 20px;
    display: flex;
    left: 20px;
    right: 0;
}
.disc{
    background: #828282;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    position: relative;
    top: -2px;
    display: inline-block;
}
