@import "./src/assets/scss/main.scss";















































.offcanvas {
    max-width: 329px !important;
    z-index: 9999 !important;
}

.offcanvas-body {
    padding-left: 0;
    padding-right: 0;
     background: url(../assets/leaf.svg) 55% 67% no-repeat, url(../assets/bgMobleMenu.svg) 90% 87% no-repeat;
}

.offcanvas-header .btn-close {
    position: absolute;
}

.offcanvas-header__logo {
    margin-left: 40px;

    img {
        width: 94px;
        height: auto;
    }
}

.mobileMenu {
    position: relative;

    &__contacts {
        padding-top: 16px;
        padding-bottom: 16px;

        padding-left: 20px;
        padding-right: 20px;
        background: url(../assets/garlicOrange.svg) 90% 50% no-repeat $orange;

        a {
            text-decoration: none;
            display: block;
            font-size: 16px;
            line-height: 107.19%;
            color: #fff;
        }
    }

    &__links {
        a,span {
            cursor: pointer;
            display: block;
            font-family: Montserrat;
            border: 1px solid #EDEDED;
            font-weight: 500;
            font-size: 16px;
            line-height: 107.19%;
            text-decoration: none;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            color: #323232;
        }
    }

    &__btn {
        position: absolute;
        bottom: 0;
    }
}


.offcanvas-body__btn{
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 33px;
    .btn-oval {
        width: 289px;
    }
}
.text-reset{
 width: 19px ;
 height: 19px;
position: absolute;
left: 20px;
   background: url(../assets/close.svg) 50% 50% no-repeat #fff;
   border: none;
}
