@import "./src/assets/scss/main.scss";































































































































































































.addMenuItemInput {
    max-width: 306px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;

    @include media-breakpoint-down(md) {
        margin-top: 15px;
    }

    label {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
    }

    input {
        background: #ebebeb;
        border-radius: 73px;
        border: none;
        padding: 15px 10px 15px 25px;
        width: 100%;
        font-family: Montserrat;
        font-size: 16px;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &--bold {
        label {
            font-weight: 700;
            padding-left: 0;
        }
    }
}

.modal-body {
    padding: 63px 31px 0 32px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 645px;
    }
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.btn-close {
    position: absolute;
    background: none;
    right: -64px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.login {
    text-align: center;

    &__header {
        font-size: 40px;
        line-height: 44px;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 0;
        }
    }

    p {
        font-family: Montserrat;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        line-height: 142.69%;
        margin-top: 14px;
        color: #343434;
    }
}

.modal-footer__desc {
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 15px;

    span {
        color: $orange;
    }
}

.modal-content {
    background: #f5f5f5;
}

.vd-picker__input {
    position: relative;

    input {}

    &:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;

        right: 23px;
    }
}

.calendar {
    position: relative;
    background: url(../assets/calendar-orange.svg) 85% 50% no-repeat !important;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

select {
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 9px;
    height: 48px;
    width: 103px;
    background: none;
    padding-left: 22px;
    padding-right: 10px;

    &:focus {
        outline: none;
    }
}

select {
    appearance: none;
    background: url(../assets/selectArr.png) 85% 50% no-repeat !important;
}
