@import "./src/assets/scss/main.scss";







































































































































































































.food-filter {
    display: flex;
    font-family: Montserrat;
    align-items: baseline;

    @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
    }
}

.food-filter__header {
    font-size: 24px;
    line-height: 109.5%;
}

.food-filter__range {
    display: flex;
    width: 498px;
    align-items: center;
    border: 1px solid #9b9b9b;
    padding: 8px 22px;
    border-radius: 9px;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        padding: 15px 22px 15px;
    }

    label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 2px;

        @include media-breakpoint-down(md) {
            width: 50%;
        }
    }

    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        white-space: nowrap;

        span {
            color: $orange;
        }
          @include media-breakpoint-down(md){
            display: flex;
            padding-left: 5px;
            padding-right: 5px;
          }
    }

    input {
        max-width: 208px;
        margin-right: 15px;
        margin-left: 15px;

        @include media-breakpoint-down(md) {
            order: -1;
            max-width: 100%;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.form-label {}

.form-range {
    &:focus,&:active{
        box-shadow: none !important;
    }
}

.food-filter__range-question {}

.food-filter__param {
     @include media-breakpoint-down(md){
        width: 100%;
     }
}

.dropdown {
    button{
   @include media-breakpoint-down(md){
        width: 100%;
     }
    }
   
}

.btn {}

.dropdown-toggle {
    font-family: Montserrat;
    border: 1px solid #9b9b9b;
    box-sizing: border-box;
    border-radius: 9px;
    padding: 13px 25px 13px 20px;
    position: relative;
    width: 298px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &:before {
        position: absolute;
        content: "";
        border: solid #5a5a5a;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        top: 16px;
        right: 13px;
        transform: rotate(403deg);
    }

    &:after {
        display: none;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 22px;
        margin-top: 10px;
    }
}

.dropdown-menu {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
}

.food-filter__question {
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
    text-decoration: none;
    flex-shrink: 0;
    background: #c7c7c7;
    margin-left: 15px;
    margin-right: 20px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 12px;
font-style:inherit;
    /* identical to box height */
    text-align: center;
    cursor: pointer;
    color: #ffffff;
     @include media-breakpoint-down(md){
        margin-right: 0;
        margin-left: 11px;
     }
}

.form-check {
     @include media-breakpoint-down(md){
        max-width:92% !important;
     }
}

.form-check-input {}

.form-check-label {}

.food-filter__choices {
    display: flex;
    align-items: center;
}

.food-filter__choices-item {}

.food-filter__choices-item-name {
    background: #ffffff;
    border: 1px solid #e8e8e8;

    border-radius: 9px;
    padding: 13px 18px;
    position: relative;
}

.food-filter__choices-item-remove {
    cursor: pointer;
    margin-left: 11px;
    margin-right: 15px;
}

input[type="range"] {
    width: 210px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;

    @include media-breakpoint-down(md) {
        order: 1;
        width: 100%;
    }
}

input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 200px;
    height: 2px;
    background: $orange;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

input[type="range"]::-webkit-slider-thumb {
    position: relative;
    height: 17px;
    width: 17px;
    top: -3px;
    background: $orange;
    border-radius: 50%;
}

input[type="range"]::-webkit-slider-thumb::before {
    position: absolute;
    content: "";
    height: 10px;
    /* equal to height of runnable track */
    width: 500px;
    /* make this bigger than the widest range input element */
    left: -502px;
    /* this should be -2px - width */
    top: 0px;
    /* don't change this */
    background: #777;
}

.form-check {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    padding-top: 17px;
    padding-bottom: 17px;
    max-width: 253px;
    margin-left: auto;
    margin-right: auto;
}

ul li:last-child {
    .form-check {
        border-bottom: none;
    }
}

.form-check-label {
    font-family: Montserrat;
    margin-left: 12px;
    font-size: 16px;
    line-height: 20px;

    color: #000000;
    margin-top: 5px;
}

.form-check-input {
    background: #e7e7e7;
    width: 23px;
    height: 23px;
}

.form-check-input:checked {
    background-color: $orange;
    border-color: $orange;
}

.form-check-input:checked[type="checkbox"] {
    background-image: none;
}

.header-block--lc {
    letter-spacing: 0px;
    font-size: 24px;
    line-height: 109.5%;

    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 109.5%;
    }
}

[data-popover="answer"] {
    margin-bottom: 30px;
    //   width: 100% !important;
    //   max-width: 280px !important;
    font-family: Montserrat;
    padding: 21px 15px 33px 21px;
    font-size: 14px;
    line-height: 142.69%;
    /* or 20px */

    color: #000000;
}

.food-filter__param-wraper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: baseline;
      @include media-breakpoint-only(md){
        margin-top: 15px;
      }
}

.food-filter__choices {
    margin-bottom: 10px;
}

.vue-popover {
    width: 330px !important;
}
