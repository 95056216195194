@import "./src/assets/scss/main.scss";

































































































$orange: #FF7728;

.first-b {
    max-width: calc(50% + 650px);
    margin-left: auto;
    display: flex;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        max-width: 100%;
        width: 100%;
    }
}

.first-b__left {
    position: relative;
    width: calc(100% - 910px);
    //width: 44%;
    margin-top: 129px;
    background: url(../assets/olives.svg) 90% 50% no-repeat;

    @include media-breakpoint-only(md) {
        margin-top: 12px;
        width: calc(100% - 500px);
        padding-left: 12px;
        background: none;
    }

    @include media-breakpoint-only(xl) {
        width: calc(100% - 700px);
    }

    @include media-breakpoint-only(lg) {
        width: calc(100% - 700px);
    }

    @include media-breakpoint-down(md) {
        background: none;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 100px;
        left: 0;
        right: 0;
        margin: auto;
        width: 123px;
        height: 152px;
        background: url(../assets/tomatoes.png) 0% 0% no-repeat, url(../assets/leafLow.svg) 0% 100% no-repeat, url(../assets/listMirror.svg) 95% 95% no-repeat;

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-only(md) {
            transform: scale(0.7);
            bottom: 30px;
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 0;
    }
}

.first-b__right {
    //  padding-top: 54%;
    // padding-bottom: 54%;
    background: url(../assets/garlic.svg) 0% 100% no-repeat, url(../assets/leaf.svg) 0% 20% no-repeat, url(../assets/bg-slider.png) 50% 50% no-repeat;
    // background-size: cover;
    position: relative;
    top: -150px;
    //width: 56%;
    width: 910px;
    height: 883px;
    //overflow: hidden;
    position: relative;
    z-index: 1;

    @include media-breakpoint-only(md) {
        width: 550px;
        background: url(../assets/bg-slider-tab.png) 50% 50% no-repeat;
        background-size: cover;

        top: inherit;
        height: 534px;
        background-size: contain;
        margin-top: -160px;
    }

    @include media-breakpoint-only(xl) {
        width: 700px;
        height: 686px;
        background: url(../assets/garlic.svg) 0% 100% no-repeat, url(../assets/leaf.svg) 0% 20% no-repeat, url(../assets/bg-slider-mob.png) 50% 50% no-repeat;
    }

    @include media-breakpoint-only(lg) {
        width: 700px;
        height: 686px;
        background: url(../assets/garlic.svg) 0% 100% no-repeat, url(../assets/leaf.svg) 0% 20% no-repeat, url(../assets/bg-slider-mob.png) 50% 50% no-repeat;
    }

    @include media-breakpoint-down(md) {
        background: url(../assets/bgSlideMob.svg) 50% 50% no-repeat;
        background-size: cover;
        width: 100%;
        top: inherit;
        height: 350px;
        margin-top: 80px;
    }

    &:after {
        content: '';
        position: absolute;
        background: url(../assets/fork.png) 50% 50% no-repeat;
        width: 56px;
        height: 356px;
        top: 42%;
        z-index: 9999;
        left: 12%;

        @include media-breakpoint-only(md) {
            content: '';
            position: absolute;
            background: url(../assets/fork.png) 50% 50% no-repeat;
            width: 32px;
            height: 187px;
            top: 43%;
            z-index: 9999;
            left: 17%;
            background-size: contain;
        }

        @include media-breakpoint-down(md) {

            display: none;
        }

        @include media-breakpoint-only(xl) {
            position: absolute;
            background: url(../assets/fork.png) 50% 50% no-repeat;
            width: 56px;
            height: 356px;
            top: 45%;
            background-size: contain;
            z-index: 9999;
            left: 12%;
            height: 242px;
        }

        @include media-breakpoint-only(lg) {
            position: absolute;
            background: url(../assets/fork.png) 50% 50% no-repeat;
            width: 56px;
            height: 356px;
            top: 45%;
            background-size: contain;
            z-index: 9999;
            left: 12%;
            height: 242px;
        }
    }

    &:before {
        content: '';
        position: absolute;

        background: url(../assets/knife.png) 50% 50% no-repeat;
        width: 44px;
        height: 356px;
        top: 42%;
        z-index: 9999;
        right: 9%;

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-only(xl) {
            content: '';
            background: url(../assets/knife.png) 50% 50% no-repeat;
            position: absolute;
            width: 30px;
            height: 242px;
            background-size: contain;
            top: 45%;
            right: 65px;
            z-index: 9999;
        }

        @include media-breakpoint-only(lg) {
            content: '';
            background: url(../assets/knife.png) 50% 50% no-repeat;
            position: absolute;
            width: 30px;
            height: 242px;
            background-size: contain;
            top: 45%;
            right: 65px;
            z-index: 9999;
        }

        @include media-breakpoint-only(md) {
            content: '';
            position: absolute;
            background: url(../assets/knife.png) 50% 50% no-repeat;
            width: 26px;
            height: 187px;
            top: 42%;
            background-size: contain;
            z-index: 9999;
            right: 7%;
        }
    }

}

.first-b__text {

    h1 {
        font-size: 65px;
        line-height: 112.69%;

        @include media-breakpoint-only(md) {
            font-size: 41px;
        }

        span {
            color: $orange;
        }

        @include media-breakpoint-down(md) {
            font-size: 35px;
            line-height: 46px;
            text-align: center;
        }
    }

    p {
        font-size: 25px;
        line-height: 106.19%;
        font-family: Montserrat;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 22px;
        }

        span {
            color: $orange;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
}

.btn-oval-large {
    @extend .btn;
    background: $orange;
    color: #fff;
    border-radius: 55px;
    padding: 22px 45px;
    font-family: Montserrat;
    font-weight: bold;

    @include media-breakpoint-down(md) {
        background: #fff;
        color: $orange;
        font-size: 16px;
        position: relative;
        top: -30px;
    }

    &:hover {
        background: none;
        border: 1px solid $orange;
        transition: all .2s ease;
        color: $orange;
    }
}

.select-menu-home {
    margin-top: -70px;

    @include media-breakpoint-down(md) {
        margin-top: 50px;
    }
}

.select-menu-home__header {
    font-size: 45px;
    line-height: 109.5%;

    span {
        color: $orange;
    }

    @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 109.5%;
    }
     @include media-breakpoint-only(md){
        font-size: 33px;
        margin-top: 26px;
     }
}

.kkal-desc {
    font-family: Montserrat;
    margin-top: 5px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 100px;
    text-align: center;
}

.header-block {
    font-size: 24px;
    line-height: 109.5%;
    //letter-spacing: -2px;
}

.food-category-items {
    display: flex;
}

.gxFoods {
    position: relative;

    @include media-breakpoint-down(md) {
        --bs-gutter-x: 10px !important;
    }
}

.container {
    //  overflow: hidden;
}

// div{
//     outline: 1px solid red;
// }
