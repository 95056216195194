@import "./src/assets/scss/main.scss";





























#app {
    //@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=PT+Serif&display=swap');
       position: relative;
    //@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
    // text-align: center;
    font-family: 'PT Serif', serif;
    color: #000;
    min-height: calc(100vh + 10px);
}

a:focus {
    box-shadow: none !important;
}
.vd-activator{
    display: block !important;
}
.dropdown-menu.show {
    z-index: 9999 !important;
}
       .cart-item__noimgs{
     height: 72px;
    width: 88px !important;

    margin-right: 25px;
      background: url(./assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
background-size:contain;
      border-radius: 12px;
    
    }
        .adminOrderItem__numbers{
        img{
            border-radius: 8px;
            max-width: 88px;
            height: 72px;
        }
    }
    .vd-picker__table-day__current{
        background:  #fff !important;
        color:#000; 
    }

