@import "./src/assets/scss/main.scss";




















































































































.top-navbar {
    @extend .container;
    position: relative;
    z-index: 1000;
    padding-bottom: 30px;
}

.top-navbar__meta {
    font-size: 16px;
    line-height: 107.19%;

    a {
        color: $orange;
         @include media-breakpoint-only(md){
            display: inline-block;
            margin-bottom: 5px;
            margin-top: 5px;
         }
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
     @include media-breakpoint-only(md){
        display: flex;
        flex-direction: column;
     }
}

.btn-round {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: #FF8D4B;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.top-navbar__logo {
    text-align: center;

    img {
        @include media-breakpoint-down(md) {
            max-width: 93px;
            height: auto;
        }
    }
}

.top-navbar__btns {
    display: flex;
    position: relative;
    z-index: 1000;
}

.btn-round--grey {
    background: #F1F1F1 !important;
}

.top-navbar-wrapper {
    background: #fff;
}

.top-navbar__burger {
    cursor: pointer;
    margin-right: 20px;
}

.cart-count {
    position: absolute;
    color: #fd7e14;
    background: #fff;
    width: 17px;
    top: -7px;
    right: -3px;
    height: 17px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 10px;

    &--orange {
        color: #fff;
        background: #fd7e14;
    }
}

/* Popover styling */

.popoverBell {
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 20px;
    position: absolute;
    top: 94px;
    right: 0;
    background: #fff;
    color: #000;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 290px;
    padding-bottom: 0;
}

.popoverBell::after,
.popoverBell::before {
    content: '';
    position: absolute;
    background: #fff;
    right: 25px;
    top: -11px;
    width: 20px;
    height: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    z-index: -1;
    /* Прячем за основным блоком */
    transform: rotate(45deg);
    /* Поворачиваем на 45º */
    -webkit-transform: rotate(45deg);
}

.popoverBell::before {
    z-index: 1;
    /* Накладываем поверх, чтобы скрыть следы тени */
    box-shadow: none;
    /* Прячем тень */
}

.popoverBell-item {
    background: url(../assets/true.svg) 100% 10% no-repeat;
    font-size: 14px;
    font-family: 'Montserrat';
    padding-bottom: 19px;
}

.popoverBell-item__number {
    font-weight: 600;

}

.popoverBell-item__status {}

.popoverBell-item__warn {

    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;

    color: #FF3528;
}

.btn-round--bg {
    background: url(../assets/bell.svg) 50% 50% no-repeat #FF8D4B;

}

.btn-round--bgs {
    background: url(../assets/bell-black.svg) 50% 50% no-repeat #F1F1F1 !important;
}

.closeAdmin {
    width: 104px;
    height: 34px;
    background: #D4D4D4;
    border-radius: 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: none;
    color: #000000;
}
.btn-dark-round{
      background:  #3F2A2C !important;
}
