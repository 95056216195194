@import "./src/assets/scss/main.scss";















    .popoverBell-item {
    background: url(../assets/true.svg) 100% 10% no-repeat ;
    font-size: 14px;
    font-family: 'Montserrat';
    padding-bottom: 19px;
}

.popoverBell-item__number {
    font-weight: 600;

}

.popoverBell-item__status {}

.popoverBell-item__warn {

    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;

    color: #FF3528;
}
.btn-round--bg{
     background: url(../assets/bell.svg) 50% 50% no-repeat #FF8D4B;

}
.btn-round--bgs{
 background: url(../assets/bell-black.svg) 50% 50% no-repeat #F1F1F1 !important;
}
