@import "./src/assets/scss/main.scss";























    .select-time {
    font-size: 24px;
    line-height: 109.5%;
    position: relative;
font-weight: 400;
    b {
        @include media-breakpoint-down(md) {
            display: block;
            margin-top: 7px;
        }
    }

    span {
        display: block;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        line-height: 109.5%;
            width: fit-content;
        color: $orange;
       cursor: pointer;
        @include media-breakpoint-down(md) {
            display: block;
            position: absolute;
            top: 35px;
            right: 20px;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 109.5%;
    }
}
