@import "./src/assets/scss/main.scss";























    .app-loader{
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #F5F5F5;
        z-index: 2222;
        display: flex;
        justify-content: center;
        align-items: center;
opacity: .8;
    }


