@import "./src/assets/scss/main.scss";


































































































.swiper-slide {
    max-width: 250px !important;
    width: 250px !important;
}

.swiper-container {
    max-width: 1295px;
    width: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
}

.swiper-button {
    position: absolute;
    top: 0;
    right: 5px;
    display: flex;
    width: 90px;
    justify-content: space-between;
}

.button-prev,
.button-next {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    border: 1px solid $orange;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        position: absolute;
        content: '';
        border: solid $orange;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
    }
}

.button-next {

    &:before {
        position: absolute;
        content: '';
        border: solid $orange;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
    }
}

.button-next {

    &:before {
        position: absolute;
        content: '';
        border: solid $orange;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
    }
}

.swiper-header {
    position: relative;
}
.swiper-slide{
    width: 250px;
}
.category-list{
    width: 100%;
}
