@import "./src/assets/scss/main.scss";







































































.footer {
    background: #F5F5F5;
    padding-top: 80px;
    padding-bottom: 40px;

    @include media-breakpoint-down(md) {
        padding-top: 33px;
        padding-bottom: 0;
    }

    &__logo {
        img {
            max-width: 100%;
            height: auto;

            @include media-breakpoint-down(md) {
                max-width: 108px;
            }
        }
    }

    &__contact {
        a {
            display: block;
            font-weight: bold;
            font-size: 16px;
            line-height: 107.19%;
            color: $orange;
        }
    }

    &__socials {
        @include media-breakpoint-down(md) {
            text-align: right;
        }

        a {
            display: inline-block;
            margin-right: 12px;
        }
    }

    &__policy {
        a {
            font-family: Montserrat;
            display: inline-block;
            margin-bottom: 18px;
            font-size: 16px;
            line-height: 107.19%;
            text-decoration: none;

            color: #323232;
        }
    }
}

.footerContactMob {
    background: #fff;
    width: 100%;
    text-align: center;
    padding-bottom: 25px;
    margin-top: 20px;
    padding-top: 30px;
}
.phoneCity{
    display: flex;
    align-items: center;
    i{
        font-style: inherit;
    }
     @include media-breakpoint-down(md){
        justify-content: center;
     }
}
