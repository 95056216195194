@import "./src/assets/scss/main.scss";
















































































.food-category-item {
    width: 100%;
    height: 77px;
    background: #F5F5F5;
    border-radius: 9px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    padding-right: 15px;
    margin-right: 10px;
     font-weight: 500 !important;
    cursor: pointer;
    font-family: 'Montserrat';

    &__imgs {
        position: relative;
        margin-right: 25px;

        &:after {
            content: '';
            position: absolute;
            width: 33px;
            z-index: 1;
            height: 23px;
            background: #FFCFB4;
            border-radius: 4px;
            bottom: -5px;
            right: -2px;
        }
    }

    &__img {
        position: relative;
        z-index: 9999;
        width: 42px;
        height: 27px;
        // background: url(../assets/salad.svg) 95% 50% no-repeat;
    }
}

.food-category {

    &__name {
        position: relative;
        z-index: 9999;
    }
}

.food-category-item__imgs.active {
    background: #000;
}

label {
    position: relative;

    input {
        display: none;
    }
}

label input:checked+span {
   // background: #3F2A2C;
    // border-radius: 9px;
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // width: 100%;
    // left: 0;
    // right: 0;
}

label input:checked+span+span+span {
   // color: #fff;
}

.activeSvg {
    font-weight: 500 !important;
    background: #3F2A2C !important; 
    color:#fff;
    svg {
 
          path{
             fill: #fff !important;
          }
    }
 
    .food-category-item__imgs:after {
        background: #6C5456;
    }
    
}
