@import "./src/assets/scss/main.scss";







































    .mobileMenu {
    position: absolute;
    top: 95px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: #F5F5F5;
    z-index: 9;
    padding-top: 30px;

}

////////////////////////////////////////////////////////////////////
.navbar-vertical-mob {
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;

    &__btn {
        @extend .btn;
        width: 100%;
        padding-top: 22px;
        padding-bottom: 23px;
        margin-bottom: 10px;
        background: #FF7728;
        border-radius: 12px;
        font-family: Montserrat;

        font-weight: 500;
        font-size: 16px;
        line-height: 109.5%;
        /* identical to box height, or 18px */

        color: #fff;

        &--white {
            background: #fff !important;
            color: #636363;
            &:hover{
                  color: #636363 !important;
            }
        }

        &:hover {
            background: $orange;
            color: #fff;
        }
    }
}

.router-link-exact-active {
    background: $orange;
    color: #fff;
}
