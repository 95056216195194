@import "./src/assets/scss/main.scss";

















































































































































































.addMenuItemInput {
    max-width: 306px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

    label {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
    }

    input {
        background: #ebebeb;
        border-radius: 73px;
        border: none;
        padding: 15px 10px 15px 15px;
        width: 100%;
        font-family: Montserrat;
        font-size: 16px;
        text-align: center;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &--bold {
        label {
            font-weight: 700;
            padding-left: 0;
        }
    }
}

.modal-body {
    padding: 63px 31px 0 32px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 645px;
    }
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.btn-close {
    position: absolute;
    background: none;
    right: -64px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.login {
    text-align: center;

    &__header {
        font-size: 40px;
        line-height: 44px;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 0;
        }
    }

    p {
        font-family: Montserrat;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        line-height: 142.69%;
        margin-top: 14px;
        color: #343434;
    }
}

.modal-footer__desc {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 15px;
    font-size: 14px;

    span {
        color: $orange;
    }
}

.invalid-feedback,
.valid-feedback {
    display: block;
}

.valid-feedback {
    color: #343434;
}

.sendAgain {
    cursor: pointer;
}
